function encodeQueryData(data: Record<string, any>) {
  return Object.keys(data)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${
          //eslint-disable-next-line no-eq-null
          data[key] == null ? '' : data[key]
        }`, //todo encodeURIComponent
    )
    .join('&');
}

export class UrlBuilder {
  params: Record<string, any>;
  _base: string;
  constructor(baseUrl: string, params?: Record<string, any>) {
    this._base = baseUrl;
    this.params = params || {};
  }

  base(baseUrl) {
    this._base = baseUrl;
    return this;
  }

  param(name, value) {
    this.params[name] = value;
    return this;
  }

  ifParam(condition, name, value) {
    if (condition) {
      return this.param(name, value);
    }
    return this;
  }

  paramsObj(params) {
    this.params = { ...this.params, ...params };
    return this;
  }

  url() {
    let query = encodeQueryData(this.params);
    if (query) {
      query = `?${query}`;
    }
    return this._base + query;
  }
}

import experiment from './experiment';
import getQueryUtils from '@wix/santa-main-r/lib/lib/common/getQueryUtils';

const queryUtil = getQueryUtils(window);

function getShouldUseDebugBundle() {
  const debugParam = queryUtil.getParameterByName('debug');
  return debugParam?.includes('all');
}
enum ScriptPathes {
  Prod = 'bundle',
  Dev = 'bundle-dev',
  ProdNewSkin = 'bundle-new-skin',
  DevNewSkin = 'bundle-dev-new-skin',
}

function isProdScript(currentSrc: string) {
  return currentSrc.includes(`/dist/${ScriptPathes.Prod}/`);
}

function getRedirectPath({
  currentSrc,
}: {
  currentSrc: string;
}): ScriptPathes | null {
  if (!isProdScript(currentSrc)) {
    return null;
  }
  const shouldUseDebugBundle = getShouldUseDebugBundle();
  const shouldUseNewSkin = experiment.isOpen('se_newWorkspace');

  if (shouldUseNewSkin) {
    return shouldUseDebugBundle
      ? ScriptPathes.DevNewSkin
      : ScriptPathes.ProdNewSkin;
  }
  if (shouldUseDebugBundle) {
    return ScriptPathes.Dev;
  }
  return null;
}

const curScriptElement = document.currentScript as HTMLScriptElement;

function loadRedirectScript(redirectPath: string) {
  const scriptElm = window.document.createElement('script');
  scriptElm.type = 'text/javascript';
  scriptElm.src = curScriptElement.src.replace(
    `/dist/${ScriptPathes.Prod}/`,
    `/dist/${redirectPath}/`,
  );

  curScriptElement.parentNode.insertBefore(scriptElm, curScriptElement);
  return;
}

const versionRegex = /\/(\d+\.\d+\.\d+)\//;

function hasVersion(url: string) {
  return Boolean(url.match(versionRegex));
}

function replaceVersionWithDist(url: string) {
  return url.replace(versionRegex, '/dist/');
}

function getPublicPath({ currentSrc }: { currentSrc: string }) {
  let publicPath = currentSrc
    .replace('//dist/', '/dist/')
    .replace(/#.*$/, '')
    .replace(/\?.*$/, '')
    .replace(/\/[^\/]+$/, '/'); //copied from webpack runtime - auto public path

  if (
    hasVersion(publicPath) &&
    !queryUtil.getParameterByName('disable_hash_dist')
  ) {
    publicPath = replaceVersionWithDist(publicPath);
  }
  return publicPath;
}

function bootstrap() {
  const currentSrc = curScriptElement.src;
  const redirectPath = getRedirectPath({ currentSrc });

  if (redirectPath) {
    loadRedirectScript(redirectPath);
    return;
  }

  const publicPath = getPublicPath({ currentSrc });

  import(/* webpackMode: "eager" */ './startup')
    .then((mod) => mod.startup({ publicPath }))
    .catch((err) => {
      console.error(err);
      throw err;
    });
}

bootstrap();

import { factory as biLoggerFactory } from '@wix/web-bi-logger/dist/src/logger';

function getCookie(name) {
  const re = new RegExp(`(?:^|\\W)${name}\s*=([^;]*)`);
  const m = window.document.cookie.match(re);
  return m ? m[1].trim() : m;
}

const getImplicitParamsGetter = ({ editorModel, window }) => {
  const permissionInfo = editorModel.permissionsInfo || {};

  const implicitFields = {
    ownerId() {
      return permissionInfo.ownerId || '';
    },
    roles() {
      const loggedInUserRoles = (permissionInfo.loggedInUserRoles || [])
        .map((loggedInUserRole) => loggedInUserRole.role)
        .join();
      this.roles = function () {
        return loggedInUserRoles;
      };
      return this.roles();
    },
    esi() {
      return editorModel.editorSessionId || '';
    },
    did() {
      return window.siteId || '';
    },
    msid() {
      return editorModel.metaSiteId || '';
    },
    ts(now) {
      return now - (window.mainLoaded || 0);
    },
    c(now) {
      return now;
    },
  };

  return function () {
    const now = Date.now();
    const payload = Object.keys(implicitFields).reduce((res, key) => {
      res[key] = implicitFields[key](now);
      return res;
    }, {});
    return payload;
  };
};

export type BiType = ReturnType<typeof createBi>;

export function createBi({ window, editorModel, suppressbi }) {
  const implicitParams = getImplicitParamsGetter({ window, editorModel });

  const biLogger = biLoggerFactory().logger();

  function sendBI(reportDef, params) {
    const endpoint = reportDef.endpoint || 'editor';
    const logParams = {
      src: reportDef.src || 38,
      evid: reportDef.evid,
      ...params,
      ...implicitParams(),
    };

    if (suppressbi) {
      window.console.log('BI:', endpoint, logParams);
    } else {
      biLogger.log(logParams, { endpoint });
    }
  }

  const bi = {
    event(reportDef, params) {
      if (typeof reportDef === 'number') {
        reportDef = { evid: reportDef };
      }
      sendBI(reportDef, params);
    },
    error: (function () {
      const errorSeverityMap = {
        recoverable: 10,
        warning: 20,
        error: 30,
        fatal: 40,
      };
      return function (reportDef, params) {
        params = params || {};
        const rd = {
          evid: 10,
          endpoint: reportDef.endpoint || 'trg',
        };
        const p: any = {
          errn: reportDef.errorName,
          errc: reportDef.errorCode,
          sev: errorSeverityMap[reportDef.severity] || reportDef.severity,
          cat: 1,
          iss: 1,
        };
        const ut = getCookie('userType');
        if (ut) {
          p.ut = ut;
        }
        Object.keys(params).forEach((param) => {
          p[param] = params[param];
        });
        sendBI(rd, p);
      };
    })(),
  };
  return bi;
}

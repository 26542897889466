import type { EditorModel, EditorLoggers } from 'types/core';
import type { StartupConfig } from './common';
import { EditorBootstrapError, EditorBootstrapErrorCode } from './errors';
import { loadMainPackages } from './loadMainPackages';

export async function initEditor(
  {
    editorLoggers,
    isHmrReload = false,
    editorModel,
  }: {
    editorLoggers: EditorLoggers;
    isHmrReload?: boolean;
    editorModel: EditorModel;
  },
  config: StartupConfig,
) {
  const { fedopsLogger } = editorLoggers;

  const { rEditor, additionalPackages } = await loadMainPackages(
    { fedopsLogger, isHmrReload, editorModel },
    config,
  );

  let builtEditor: ReturnType<typeof rEditor.editorMain.build>;
  try {
    fedopsLogger.appLoadingPhaseStart('editor-build-app');
    builtEditor = rEditor.editorMain.build(
      additionalPackages,
      config.measureFrameRate,
      editorLoggers,
    );
    fedopsLogger.appLoadingPhaseFinish('editor-build-app');
  } catch (error) {
    throw new EditorBootstrapError('Failed to build editor', {
      code: EditorBootstrapErrorCode.EditorBuildAppError,
      originalError: error,
    });
  }

  if (config.shouldRender) {
    try {
      fedopsLogger.appLoadingPhaseStart('editor-render-app');
      rEditor.editorMain.render(builtEditor);
      fedopsLogger.appLoadingPhaseFinish('editor-render-app');
    } catch (error) {
      throw new EditorBootstrapError('Failed to render editor', {
        code: EditorBootstrapErrorCode.EditorRenderAppError,
        originalError: error,
      });
    }
  }

  const clean = () => rEditor.editorMain.__unmountEditor();
  return { builtEditor, clean };
}

// NOTE: initial code is cloned from https://github.com/wix-private/santa-core/blob/master/santa-main-r/src/lib/common/logger.js

import type * as Sentry from '@sentry/browser';
import type { FedopsLogger } from '@wix/fedops-logger';
import type { BILoggerModel, EditorModel } from 'types/core';
import type { SentryLogger } from 'types/sentry';
import type { EditorParams } from '../../../common';

const buildDefaultTagStore = (
  editorModel: Partial<EditorModel>,
  biLoggerModel: Partial<BILoggerModel>,
) => ({
  publicUrl: editorModel.publicUrl,
  numberOfPages: editorModel.siteHeader?.pageIdList?.pages?.length,
  revision: editorModel.siteHeader?.revision,
  geo: editorModel.geo,
  metaSiteId: editorModel.metaSiteId,
  isDesktopEditor: true,
  isMobileEditor: false,
  editorLoaded: false,
  isPreview: false,
  ds: biLoggerModel.origin,
});

const buildDefaultContext = (editorModel: Partial<EditorModel>) => ({
  previewUrl: editorModel.previewUrl,
  runningExperiments: editorModel.runningExperiments,
  isPublished: editorModel.siteHeader?.published,
  premiumFeatures: editorModel.metaSiteData?.premiumFeatures,
});

const isWixCodeError = (event: Sentry.Event): boolean => {
  const exceptionFrames =
    event.exception?.values?.[0]?.stacktrace?.frames ?? [];

  return exceptionFrames
    .filter((frame) => frame && typeof frame.filename === 'string')
    .some(
      (frame) =>
        frame.filename.includes('wixCode') ||
        frame.filename.includes('wix-code'),
    );
};

export const createMockSentryLogger = (): SentryLogger => ({
  captureException: console.error as any,
  captureMessage: console.log as any,
  setTags: (() => {}) as any,
  setExtras: (() => {}) as any,
  addBreadcrumb: (() => {}) as any,
});

const shouldSkipEvent = (message: string = ''): boolean =>
  message.includes(
    "play() failed because the user didn't interact with the document first",
  ) ||
  message.includes('The play() request was interrupted by a call to pause()');

export const createSentryLogger = ({
  fedopsLogger,
  biLoggerModel = {},
  editorParams,
  editorModel = {},
}: {
  fedopsLogger: FedopsLogger;
  editorParams: EditorParams;
  editorModel: Partial<EditorModel>;
  biLoggerModel: Partial<BILoggerModel>;
}): SentryLogger => {
  if (!window.Sentry) {
    return createMockSentryLogger();
  }

  let Sentry = window.Sentry;
  Sentry.onLoad(() => {
    // NOTE: before Sentry is loaded, temp version of Sentry is used, which works incorrect after real Sentry is loaded
    // https://github.com/wix-private/editor-server/blob/21ef7c00a9d4cb5285c6c16af2530a2b87454938/wix-html-server/wix-html-editor/wix-html-editor-webapp/src/main/resources/views/editor/common/sentry.vm#L1
    Sentry = window.Sentry;
  });

  // init real sentry loading (instead of temp one of defined in the html[1])
  // [1]: https://github.com/wix-private/editor-server/blob/21ef7c00a9d4cb5285c6c16af2530a2b87454938/wix-html-server/wix-html-editor/wix-html-editor-webapp/src/main/resources/views/editor/common/sentry.vm#L1
  Sentry.forceLoad();

  if (!editorParams.isIndsideEditorX) {
    const release =
      editorModel.editorVersion && editorModel.editorVersion !== 'unknown'
        ? editorModel.editorVersion
        : undefined;
    const environment =
      !editorParams.isLocal && release ? 'production' : 'development';

    Sentry.init({
      environment,
      release,
      enabled: editorParams.isBiErrorsAndFedopsEnabled,
    });
  }

  Sentry.configureScope((scope: Sentry.Scope) => {
    scope.setTags(buildDefaultTagStore(editorModel, biLoggerModel) as any);
    scope.setExtras(buildDefaultContext(editorModel));
    scope.setUser({
      id: editorModel.permissionsInfo?.loggedInUserId,
      roles: editorModel.permissionsInfo?.loggedInUserRoles,
    });

    scope.addEventProcessor((event, hint) => {
      if (
        shouldSkipEvent(
          typeof hint.originalException === 'string'
            ? hint.originalException
            : hint.originalException?.message,
        )
      ) {
        return null;
      }

      // report fedops error once per loading phase or interaction
      // https://github.com/wix-private/fed-infra/tree/master/fedops/fedops-logger#client-side-errors-sentry
      fedopsLogger.reportErrorThrownOncePerFlow();
      fedopsLogger.interactionStarted('error' as any); //this is a workaround to get error rate until we will have support for postgresSQL in fedonomy

      // Get current fedops interactions or phase (used internal api of fedopsLogger.reportErrorThrownOncePerFlow())
      // https://github.com/wix-private/fed-infra/tree/0fea1fe0fc2138b637bd676e9a4be236f3869610/fedops/fedops-logger#error-rate
      // https://github.com/wix-private/fed-infra/blob/0fea1fe0fc2138b637bd676e9a4be236f3869610/fedops/fedops-logger/src/base-logger.js#L834
      // TODO: review after discussion https://wix.slack.com/archives/C3RTVMWV8/p1618500580095100
      // @ts-expect-error
      event.extra.fedopsFlows = fedopsLogger._getFlowsOfError?.();

      if (isWixCodeError(event)) {
        event.tags = Object.assign(event.tags || {}, {
          'wix-code-error': true,
        });
        event.fingerprint = (event.fingerprint || []).concat([
          'wix-code-error',
        ]);
      }

      if (event.tags?.hasOwnProperty('whiteScreenOfDeath')) {
        // https://docs.sentry.io/platforms/node/data-management/event-grouping/sdk-fingerprinting/
        event.fingerprint = ['{{ default }}', 'whiteScreenOfDeath'];
      }

      return event;
    });
  });

  return {
    captureException: (exception, captureContext) => {
      // captureContext as 2nd arg is not available in current sentry version, so use withScope
      Sentry.withScope((scope: Sentry.Scope) => {
        if (captureContext) {
          if ('tags' in captureContext) {
            scope.setTags(captureContext.tags);
          }

          if ('extra' in captureContext) {
            scope.setExtras(captureContext.extra);
          }

          if ('level' in captureContext) {
            scope.setLevel(captureContext.level);
          }
        }

        return Sentry.captureException(exception);
      });

      // Sentry.captureException type expects an eventId as return type (see `SentryLogger`)
      return '';
    },
    captureMessage: (message, level) => {
      return Sentry.captureMessage(message, level);
    },
    setTags: (tags) => {
      Sentry.configureScope((scope: Sentry.Scope) => {
        scope.setTags(tags);
      });
    },
    setExtras: (extras) => {
      Sentry.configureScope((scope: Sentry.Scope) => {
        scope.setExtras(extras);
      });
    },
    addBreadcrumb: (breadcrumb) => {
      Sentry.addBreadcrumb(breadcrumb);
    },
  };
};

import getQueryUtils from '@wix/santa-main-r/lib/lib/common/getQueryUtils';
import getSubdomain from '@wix/santa-main-r/lib/lib/common/getSubdomain';
import identifyForFullStory from './identifyForFullStory';
import instrument from './instrument';
import { createEditorLoggers } from './editorLoggers';
import joinURL from '@wix/santa-main-r/lib/lib/common/joinURL';
import overrideScriptsLocationMapFromQuery from '@wix/santa-main-r/lib/lib/common/overrideScriptsLocationMapFromQuery';
import storageUtil from '@wix/santa-main-r/lib/lib/common/storageUtil';
import { setupRequire } from './require-config';
import requirejs from 'requirejs';
import { requirePromise, StartupConfig, EditorParams } from '../common';
import { loadCss } from './loadCss';
import { renderPreview } from './renderIframe/renderIframe';
import { getCSSPaths } from './get-rjs-paths';
import { initEditor } from '../entry';
import {
  getBaseVersion,
  registerToTabSwitchEvent,
  registerToTabLeaveEvent,
} from './utils';

import './jsonWithFallback';
const { versions } = require('../../../gen/options.json');
import experiment from './experiment';

const queryUtil = getQueryUtils(window);

window.define('experiment', [], () => experiment);
window.define('bluebird', [], () => Promise);
window.define('@sentry/browser', [], () => window.Sentry);

window.joinURL = joinURL;
window.queryUtil = queryUtil;
window.persistent = storageUtil(window); // TODO: how is this used?
window.identifyForFullStory = identifyForFullStory;

if (window.customRenderSite !== undefined) {
  // allows to override this in other html files as starting points
  window.customRenderSite();
}

function applyTopologyOverriding() {
  const versionRegExp = /1\.\d+\.\d+/g;
  window.serviceTopology.scriptsLocationMap['santa-langs'] =
    window.serviceTopology.scriptsLocationMap['santa-langs'].replace(
      versionRegExp,
      versions['santa-langs'],
    );
  window.serviceTopology.scriptsLocationMap =
    overrideScriptsLocationMapFromQuery(
      window.serviceTopology.scriptsLocationMap,
      queryUtil.getParameterByName('scriptsLocations'),
    );
}

const isAddress = function isAddress(str) {
  return /^(https?:)?\/\//.test(str);
};

const isLocal = (path: string) =>
  /^(https?:)?\/\/localhost|local.wix.com($|[/:])/.test(path);

async function hackRequirejs() {
  // TODO: cleanup when all deps migrated to the `@wix/` scope (`@wix/santa-editor-symbols`, `@wix/santa-editor-utils`, etc..)
  // https://wix.slack.com/archives/C01QVAD2UP2/p1614869864037300?thread_ts=1614860977.003700&cid=C01QVAD2UP2
  const TEMP_WIX_SCOPED_MAP = {
    'wix-base-ui': '@wix/wix-base-ui',
    'wix-ui-santa': '@wix/wix-ui-santa',
    'santa-editor-utils': '@wix/santa-editor-utils',
    'santa-editor-symbols': '@wix/santa-editor-symbols',
  };

  await __webpack_init_sharing__('default');

  window.__debug_project_references_scopes__ = __webpack_share_scopes__;

  const origLoad = requirejs.load;
  requirejs.load = (ctx, name, ...args) => {
    const scope = __webpack_share_scopes__.default;
    if (scope) {
      const keys = Object.keys(scope);

      const packageNameToLoad = TEMP_WIX_SCOPED_MAP[name] || name;
      const packageNameToDefine = name;

      if (keys.includes(packageNameToLoad)) {
        const versions = Object.keys(scope[packageNameToLoad]);
        if (versions.length !== 1) {
          throw new Error(`versions.length is not 1, ${versions}`);
        }
        scope[packageNameToLoad][versions[0]].get().then((factory) => {
          window.define(packageNameToDefine, factory);
          ctx.completeLoad(packageNameToDefine);
        });
        return;
      }
    }

    return origLoad(ctx, name, ...args);
  };
}

function getBaseUrl(name: string, base: string | undefined, serviceTopology) {
  if (!base) {
    return serviceTopology.scriptsLocationMap[name];
  }
  if (isAddress(base)) {
    return base;
  }
  return joinURL(serviceTopology.scriptsDomainUrl, 'services', name, base);
}

//used by editorx bridge
export async function startupResponsive({
  publicPath,
}: {
  publicPath: string;
}) {
  // window.__dont_inject_css_extract_plugin__ = true;
  window.__webpack_overridable_esm_modules__ = true; //used by AllowMutateEsmExports plugin

  return _startup({
    isIndsideEditorX: true,
    loadMainCss: false,
    measureFrameRate: false,
    shouldBiErrorsAndFedops: false,
    shouldRender: false,
    shouldLoadPolyfills: false,
    publicPath,
    stylablePanelTheme: 'editorx-theme',
  });
}

export async function startup({ publicPath }: { publicPath: string }) {
  return _startup({
    isIndsideEditorX: false,
    loadMainCss: true,
    measureFrameRate: true,
    shouldBiErrorsAndFedops: true,
    shouldRender: true,
    shouldLoadPolyfills: true,
    publicPath,
  });
}

async function _startup(config: StartupConfig) {
  const { shouldBiErrorsAndFedops, publicPath } = config;
  // TODO: use `editorParams` instead of `queryUtil.getParameterByName('x')` across the whole code
  const editorParams: EditorParams = {
    isIndsideEditorX: config.isIndsideEditorX,
    isLocal: isLocal(queryUtil.getParameterByName('EditorSource')),
    isDebug: queryUtil.getParameterByName('debug'),
    isQA: queryUtil.isParameterTrue('isqa'),
    isBiErrorsAndFedopsEnabled:
      !queryUtil.isParameterTrue('suppressbi') &&
      !queryUtil.isParameterTrue('isqa') &&
      shouldBiErrorsAndFedops,
  };

  __webpack_public_path__ = publicPath;

  window.afterEditorPrefetch = false; //TODO remove this;

  const { loggerModel: biLoggerModel, editorModel, serviceTopology } = window;

  if (editorModel) {
    editorModel.languageCode =
      queryUtil.getParameterByName('lang') || editorModel.languageCode;

    const baseUrlWithVersion = getBaseUrl(
      'santa-editor',
      window.editorBase,
      serviceTopology,
    );

    editorModel.editorBase = baseUrlWithVersion;
    editorModel.editorVersion = getBaseVersion(baseUrlWithVersion);
  }

  const editorLoggers = createEditorLoggers({
    biLoggerModel,
    editorParams,
    editorModel,
  });

  const { fedopsLogger } = editorLoggers;

  window.mainLoaded = Date.now();
  fedopsLogger.appLoadingPhaseStart('main-chunk');

  applyTopologyOverriding();

  setupRequire({ editorModel, local: editorParams.isLocal, versions });

  renderPreview({
    experiment,
    editorModel,
    serviceTopology,
    fedopsLogger,
  });

  window.initiatedSentry = editorLoggers.sentryLogger;

  instrument({
    window,
    requirejs,
    queryUtil,
    editorModel,
    shouldBiErrorsAndFedops,
  });

  registerToTabSwitchEvent(window.bi.event);
  registerToTabLeaveEvent();

  if (editorModel) {
    const { performance } = window;
    const navigation = performance ? performance.navigation : undefined;
    const navigationType = navigation ? navigation.type : undefined;
    //newer implementation window.performance.getEntriesByType("navigation")[0].type;

    //MAIN_R_LOADED evt
    window.bi.event(458, {
      // main-r loaded event
      prefetch: window.afterEditorPrefetch,
      esi: window.editorModel.editorSessionId,
      msid: window.editorModel.metaSiteId,
      navigationType,
    });
  }

  try {
    window.document.domain = getSubdomain(window.document.domain);
  } catch (e) {
    // empty
  }

  await hackRequirejs();

  const cssMapping = getCSSPaths({
    // editorModel,
    stylablePanelTheme: config.stylablePanelTheme,
  });
  window.__loadPromiseRequirejs__ = async (name: string) => {
    const modPromise = requirePromise([name]);
    const cssPromises = (cssMapping[name] || []).map(loadCss);
    const [resModule] = await Promise.all([modPromise, ...cssPromises]);
    return resModule;
  };

  const { builtEditor: result, clean } = await initEditor(
    {
      editorLoggers,
      editorModel,
    },
    config,
  ).catch((error) => {
    console.error(error);
    editorLoggers.sentryLogger.captureException(error, {
      tags: {
        operation: 'editor-bootstrap',
      },
    });

    throw error;
  });

  if (module.hot) {
    module.hot.data = { clean };
    const hmr = await (await import('../hot')).initializeHmr();

    module.hot.accept('../entry', async () => {
      hmr.request(async () => {
        module.hot.data.clean();
        const newReditor: typeof import('../entry') = require('../entry');
        const { clean } = await newReditor.initEditor(
          { editorLoggers, isHmrReload: true, editorModel },
          config,
        );
        module.hot.data = { clean };
      });
    });
  }

  fedopsLogger.appLoadingPhaseFinish('main-chunk');
  return result;
}

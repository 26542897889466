import requirejs from 'requirejs';

const importRequire = (mod: string) =>
  new Promise((resolve, reject) => requirejs([mod], resolve, reject));

type PackagesArray = Array<[string, Promise<any>]>;

async function fetchPackages(packages: PackagesArray) {
  const modulesResults = await Promise.all(packages.map((p) => p[1]));

  return packages.reduce((obj, packageDesc, ind) => {
    obj[packageDesc[0]] = modulesResults[ind];
    return obj;
  }, {});
}

export async function loadAdditionalPackages({ queryUtil }) {
  // const packagesArr = ['santa-editor-symbols', 'rEditor', 'compPanelInfra', 'core', 'tpa',
  //     'util', 'zepto', 'react', 'reactDOM', 'lodash', 'tslib', 'superAppMenuInfra',
  //     'savePublish', 'panels', 'experiment', 'staticAssets', 'textControls', 'mediaFrame', 'keyboardMaster',
  //     'baseUI', 'uiAnimations', 'panelUtils', 'mediaServices', 'color', 'pms', 'json',
  //     'gfppData', 'backgroundPresets', 'styles', 'pagesMenu', 'popups']
  const packagesArr: PackagesArray = [];
  if (queryUtil.isParameterTrue('isqa')) {
    packagesArr.push(
      ['qaAutomation', importRequire('qaAutomation')],
      ['debugTools', import('@/debugTools')],
      ['react', importRequire('react')],
      ['reactDOM', importRequire('react-dom')],
    );
  }
  const commonIntegration = (): PackagesArray => [
    ['tpa', import('@/tpa')],
    ['util', import('@/util')],
    ['savePublish', import('@/savePublish')],
    ['panels', import('@/panels')],
  ];

  if (queryUtil.isParameterTrue('isTpaIntegration')) {
    packagesArr.push(
      ['tpaIntegrationEditor', importRequire('tpaIntegrationEditor')],
      ['jasmine', importRequire('jasmine')],
      ['jasmine-html', importRequire('jasmine-html')],
      ...commonIntegration(),
    );
  }
  if (queryUtil.isParameterTrue('isUiLibIntegration')) {
    packagesArr.push(
      ['uiLibIntegrationEditor', importRequire('uiLibIntegrationEditor')],
      ['jasmine', importRequire('jasmine')],
      ['jasmine-html', importRequire('jasmine-html')],
      ...commonIntegration(),
    );
  }
  if (queryUtil.isParameterTrue('isPlatformIntegration')) {
    packagesArr.push(
      ['platform', import('@/platform')],
      ['platformIntegrationEditor', importRequire('platformIntegrationEditor')],
      ['tpa', import('@/tpa')],
      ['jasmine', importRequire('jasmine')],
      ['jasmine-html', importRequire('jasmine-html')],
    );
  }

  const packagesObj = await fetchPackages(packagesArr);

  return packagesObj;

  // if (languagePackageName) {
  //     p.addPanel.sections.applyTranslatedSections(p[languagePackageName]) //TODO
  // }
}

import type { BiType } from './biLogger';
import { createBi } from './biLogger';

function registerErrorHandlers({
  requirejs,
  window,
  bi,
}: {
  requirejs: any;
  window: any;
  bi: BiType;
}) {
  let sendErrorOnce: any = function (errorName, errorCode, severity) {
    //eslint-disable-line func-style
    sendErrorOnce = function () {}; // only report one error per session
    const params = Array.prototype.slice
      .call(arguments, 3)
      .reduce((result, arg, index) => {
        result['p' + (index + 1)] = arg; //eslint-disable-line prefer-template
        return result;
      }, {});
    bi.error(
      {
        errorName,
        errorCode,
        severity,
      },
      params,
    );
  };

  const { onerror } = window;
  window.onerror = function (errorMsg, url, line, column, err) {
    const where = err?.stack ? `${err.stack}` : url;
    const { href } = window.location;
    sendErrorOnce('JAVASCRIPT_ERROR', 111022, 'fatal', errorMsg, where, href); // JAVASCRIPT_ERROR from packages/core/bi/errors.js
    if (onerror) {
      return onerror.apply(this, arguments);
    }
  };

  if (window.console) {
    const { error } = console;
    if (error) {
      console.error = function (...args) {
        const { href } = window.location;
        sendErrorOnce('CONSOLE_ERROR', 111023, 'error', href, ...args); // CONSOLE_ERROR from packages/core/bi/errors.js
        return error.apply(this, args);
      };
    }
  }

  requirejs.onError = function (err) {
    // because ajv requires js-beautify synchronously even if does not use
    if (
      err.message?.indexOf &&
      err.message.indexOf(
        'Module name "js-beautify" has not been loaded yet',
      ) === 0
    ) {
      return;
    }
    const {
      requireModules = [],
      message,
      stack,
      errn = 'REQUIREJS_ERROR',
      errc = 111024,
      severity = 'fatal',
      requireType,
    } = err;
    const modules = requireModules.join(';');
    const what = stack ? '' + stack : message; //eslint-disable-line prefer-template
    const { href } = window.location;
    sendErrorOnce(errn, errc, severity, what, modules, href, requireType); // REQUIREJS_ERROR from packages/core/bi/errors.js
  };
}

export default function instrument({
  window,
  requirejs,
  queryUtil,
  editorModel,
  shouldBiErrorsAndFedops,
}) {
  editorModel = editorModel || {};

  const { editorVersion } = editorModel;

  const suppressbi = queryUtil.isParameterTrue('suppressbi');
  const isDebug = queryUtil.getParameterByName('debug');

  const { performance } = window;
  if (performance?.mark) {
    if (performance.setResourceTimingBufferSize) {
      if ('onresourcetimingbufferfull' in performance) {
        performance.onresourcetimingbufferfull = (function () {
          let maxSize = 150;
          return function () {
            maxSize *= 2;
            performance.setResourceTimingBufferSize(maxSize);
          };
        })();
      } else {
        performance.setResourceTimingBufferSize(isDebug ? 1000 : 300);
      }
    }
    performance.mark('main-r loaded');
  }

  const bi = createBi({ window, editorModel, suppressbi });

  if (!window.bi) {
    window.bi = bi;
  }

  const forceBi = queryUtil.isParameterTrue('bi');

  const shouldRegisterErrors =
    editorVersion && !isDebug && !suppressbi && shouldBiErrorsAndFedops;

  if (forceBi || shouldRegisterErrors) {
    registerErrorHandlers({ window, requirejs, bi });
  }
}

// NOTE: initial code is cloned from https://github.com/wix-private/santa-core/blob/master/santa-main-r/src/lib/common/loggerFactory.js

import {
  PublishMethods,
  factory as createBILogger,
} from '@wix/web-bi-logger/dist/src/logger';
import { create as createFedopsLogger } from '@wix/fedops-logger';
import type { Factory as BILoggerFactory } from '@wix/web-bi-logger/dist/src/logger';
import type { FedopsLogger as BaseFedopsLogger } from '@wix/fedops-logger';
import type { BILoggerModel } from 'types/core';

const BI_ENDPOINT = 'editor-performance';
const PRESET = 'EDITOR';

export type { BILoggerFactory, BaseFedopsLogger };

export type ShouldBeMutedForFedopsFn = () => boolean;

const createEditorBiLoggerFactoryForFedops = (
  biLoggerModel: Partial<BILoggerModel>,
  shouldBeMutedForFedops: ShouldBeMutedForFedopsFn = () => false,
): BILoggerFactory =>
  createBILogger({
    publishMethod: PublishMethods.Auto,
    useBatch: true,
  })
    .setMuted(shouldBeMutedForFedops())
    .withUoUContext({
      msid: biLoggerModel.metaSiteId,
      visitorId: biLoggerModel.editorSessionId,
      siteMemberId: () => biLoggerModel.siteMemberId,
    })
    .updateDefaults({
      is_rollout: biLoggerModel.is_rollout,
      origin: biLoggerModel.origin,
      type: 'none',
      // NOTE: ts is used to show not only duration of phase, but also start of phase
      // example: https://tableau.wixpress.com/#/site/Editor/views/EDIXperformancedashboardbasedonfedops-daily/LoadingPhasesGantt?:iid=5
      ts: () => Math.round(window.performance.now()),
    });

const createEditorFedopsLogger = <TInteractionName>(
  biLoggerModel: Partial<BILoggerModel>,
  biLoggerFactory: BILoggerFactory,
): BaseFedopsLogger<TInteractionName> =>
  createFedopsLogger<TInteractionName>(biLoggerModel.fedOpsAppName, {
    // NOTE: endpoint is real param, but not available in types
    // https://github.com/wix-private/fed-infra/blob/9feba947b862614301e0057fda2b35f4dd348b86/fedops/fedops-logger/src/reporter/reporter-factory.js#L9
    // @ts-ignore
    endpoint: BI_ENDPOINT,
    isServerSide: false,
    biLoggerFactory,
    phasesConfig: 'SEND_ON_FINISH',
    metasiteId: biLoggerModel.metaSiteId,
    presetType: PRESET,
    paramsOverrides: {
      // details: https://wix.slack.com/archives/CQGJP31CM/p1610723500080300?thread_ts=1610722235.076400&cid=CQGJP31CM
      is_rollout: biLoggerModel.is_rollout,
    },
  });

export { createEditorBiLoggerFactoryForFedops, createEditorFedopsLogger };

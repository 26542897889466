import type { Versions } from './types';
import type { ServiceTopology } from 'types/core';

function unpkg(pkg: string, v: string, p: string) {
  return 'https://static.parastorage.com/unpkg/'
    .concat(pkg, '@')
    .concat(v, '/')
    .concat(p); // return `//unpkg.parastorage.com/${pkg}@${v}/${p}`
}

function unpkgObj(pkg: string, v: string, min: string, debug: string) {
  return {
    min: unpkg(pkg, v, min),
    source: unpkg(pkg, v, debug),
  };
}

function node(m: string, p: string) {
  return 'node_modules/'.concat(m, '/').concat(p);
}

function nodeObj(m: string, min: string, debug: string) {
  return {
    min: node(m, min),
    source: node(m, debug),
  };
}

export function scriptLocation(
  serviceTopology: ServiceTopology,
  name: string,
  fallback?: string,
) {
  return (
    (serviceTopology && serviceTopology.scriptsLocationMap[name]) || fallback
  );
}

export function unpkgOrNode(
  versions: Versions,
  local: boolean,
  m: string,
  p: string,
) {
  const actualLocal = isLocal(versions, local, m);
  return actualLocal ? node(m, p) : unpkg(m, versions[m], p);
}

export function isLocal(versions: Versions, local: Boolean, m: string) {
  return local || versions[m] === 'link';
}

export function unpkgOrNodeObj(
  versions: Versions,
  local: boolean,
  m: string,
  min: string,
  debug: string,
) {
  const actualLocal = isLocal(versions, local, m);
  return actualLocal
    ? nodeObj(m, min, debug)
    : unpkgObj(m, versions[m], min, debug);
}

export const VERSION_REGEXP = /\/(\d+\.\d+\.\d+)\/?$/;

export function getBaseVersion(url: string) {
  const semverMatches = url.match(VERSION_REGEXP);
  return semverMatches && semverMatches[1];
}

export function registerToTabSwitchEvent(biEvent) {
  window.addEventListener('visibilitychange', function () {
    const uuid = window.editorModel.permissionsInfo.ownerId;
    biEvent(1140, {
      prefetch: window.afterEditorPrefetch,
      esi: window.editorModel.editorSessionId,
      msid: window.editorModel.metaSiteId,
      uuid,
      actionName: document.hidden ? 'suspend' : 'activate',
    });
  });
}

export function registerToTabLeaveEvent() {
  window.onunload = function () {
    const uuid = window.editorModel.permissionsInfo.ownerId;
    const { metaSiteId } = window.editorModel;
    const url = new URL(`${window.serviceTopology.biServerUrl}/editor`);
    url.searchParams.set('src', '38');
    url.searchParams.set('evid', '1138');
    url.searchParams.set('uuid', uuid);
    url.searchParams.set('msid', metaSiteId);
    url.searchParams.set('ts', Date.now().toString());
    fetch(url.href, { keepalive: true });
  };
}

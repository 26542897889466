import { factory } from '@wix/web-bi-logger';

import type { EditorModel } from 'types/core';
import type { BiLoggerInitialParams } from 'types/bi';

type ValuesOfReturnFnValues<T extends object> = {
  [K in keyof T]: T[K] | (() => T[K]);
};

function getCookie(name): string {
  const re = new RegExp(`(?:^|\\W)${name}\s*=([^;]*)`);
  const m = window.document.cookie.match(re);
  return m ? m[1].trim() : null;
}

const last = <T>(arr: T[]) => arr[arr.length - 1];

const getBsiParams = () => {
  const bsiCookie = getCookie('bSession');

  if (!bsiCookie) {
    return {};
  }

  const [bsi, bsi_page_number] = bsiCookie.split('|');

  return {
    browsing_session: bsi,
    bsiPageNumber: bsi_page_number,
    bsi: bsiCookie,
  };
};

const getInitialParams = ({ editorModel }: { editorModel: EditorModel }) => {
  const { editorBase, metaSiteId, editorSessionId, permissionsInfo } =
    editorModel;

  const params: ValuesOfReturnFnValues<BiLoggerInitialParams> = {
    esi: editorSessionId,
    editor_version: last(editorBase.split('/')) || editorBase,
    ts: () => Math.round(Date.now() - (window.mainLoaded || 0)),
    msid: metaSiteId,
    ...getBsiParams(),
  };

  if (permissionsInfo) {
    params.ownerId = permissionsInfo.ownerId;
    params.roles = permissionsInfo.loggedInUserRoles
      .map((userRole: any) => userRole.role)
      .join(',');
  }

  const memoryInfo = (window?.performance as any)?.memory;

  if (memoryInfo) {
    params.totalJsHeapSize = () => memoryInfo?.totalJSHeapSize;
    params.usedJsHeapSize = () => memoryInfo?.usedJSHeapSize;
    params.jsHeapSizeLimit = () => memoryInfo?.jsHeapSizeLimit;
  }

  return params;
};

export const createBiLoggerFactory = ({
  editorModel,
  shouldBeMuted = false,
}: {
  editorModel: EditorModel;
  shouldBeMuted: boolean;
}) => {
  const initialDefaults = getInitialParams({ editorModel });

  return factory({
    useBatch: true,
  })
    .setMuted(shouldBeMuted)
    .updateDefaults(initialDefaults);
};

import { shouldLoadMobileEditor } from './shouldLoadMobile';
import { UrlBuilder } from './urlBuilder';
import type { ServiceTopology, EditorModel } from 'types/core';
import type { Experiment } from 'experiment';

//this extracts the guid, but only if there's a slash before it - which is where the siteId is in the url (not in a query)
const SITE_ID_REGEX =
  /\/([a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12})/;

const getInitialAppStudioModel = () => (window as any).appStudioModel;

const getAppDefIdIfExists = () => getInitialAppStudioModel()?.devSiteAppDefId;

const isAppStudio = () => getInitialAppStudioModel() !== undefined;

function getDsOrigin() {
  return isAppStudio() ? 'appBuilder' : 'Editor1.4';
}

const defaultParamsToPassToPreview = [
  'metaSiteId',
  'ReactSource',
  'BoltSource',
  'BoltDsSource',
  'viewerSource',
  'DmSource',
  'SantaVersions',
  'baseVersion',
  'boltTag',
  'isqa',
  'editorSessionId',
  'layoutMechanism',
  'experiments',
  'experimentsoff',
  'viewerexp',
  'suppressbi',
  'WixCodeRuntimeSource',
  'sampleratio',
  'hot',
  'dsQTrace',
  'autosaveRestore',
  'disableSave',
  'debugApp',
  'closeSectionGaps',
  'editorSdkSource',
  'editorPlatformAppSources',
  'viewerPlatformAppSources',
  'PlatformContainerSource',
  'PlatformContainerHttp',
  'esi',
  'configName',
  'wixCodeForceKibanaReport',
  'overridePlatformBaseUrls',
  'viewerPlatformOverrides',
  'ooiInEditorOverrides',
  'editorScriptUrlOverride',
  'tpaWidgetUrlOverride',
  'tpaMobileUrlOverride',
  'tpaSettingsUrlOverride',
  'widgetsUrlOverride',
  'branchId',
  'schemaDevMode',
  'editorScriptHot',
];

function getSiteId(url) {
  const siteIdMatch = url.match(SITE_ID_REGEX);
  return siteIdMatch?.length && siteIdMatch[1];
}

export function getPreviewUrl({
  serviceTopology,
  editorLocation,
  editorModel,
  experiment,
}: {
  editorLocation: Location;
  serviceTopology: ServiceTopology;
  editorModel: EditorModel;
  experiment: Experiment;
}) {
  const EDITOR_ROOT =
    serviceTopology.editorServerRoot || 'http://editor.wix.com/html/editor/web';
  const PREVIEW = `${EDITOR_ROOT}/renderer/render/`;
  const DOCUMENT_PREVIEW = `${PREVIEW}document/`;
  const TEMPLATE_PREVIEW = `${PREVIEW}template/`;
  const NEW_EDITOR = `${EDITOR_ROOT}/renderer/new?`;

  const isNewSite = editorLocation.href.indexOf(NEW_EDITOR) === 0;
  const baseUrl = isNewSite ? TEMPLATE_PREVIEW : DOCUMENT_PREVIEW;
  const editorURL = new URL(editorLocation.href);

  const editorQuery: Record<string, string> = {};

  editorURL.searchParams.forEach((val, key) => {
    editorQuery[key] = val;
  });

  editorQuery.debug = editorQuery.debug === 'editor' ? '' : editorQuery.debug;
  editorQuery.editorSessionId = editorModel.editorSessionId;
  editorQuery.esi = editorModel.editorSessionId;

  if (editorModel.branchId) {
    editorQuery.branchId = editorModel.branchId;
  }

  const showMobileViewQueryParam = Object.keys(editorQuery).find(
    (key) => key.toLowerCase() === 'showmobileview',
  );

  const additionalParams = {};

  Object.keys(editorQuery).forEach((key) => {
    if (
      key.endsWith('-override') ||
      defaultParamsToPassToPreview.includes(key)
    ) {
      additionalParams[key] = editorQuery[key];
    }
  });

  const builder = new UrlBuilder(
    baseUrl + (editorQuery.siteId || getSiteId(editorLocation.href)),
  )
    .param('isEdited', 'true')
    .param('isEditor', 'true')
    .param('isSantaEditor', 'true')
    .param('dsOrigin', getDsOrigin())
    .param('controllersUrlOverride', editorQuery.controllersUrlOverride)
    .ifParam(editorQuery.debugViewer, 'debug', editorQuery.debugViewer)
    .ifParam(editorQuery.petri_ovr, 'petri_ovr', editorQuery.petri_ovr)
    .ifParam(experiment.isOpen('wixstaff'), 'iswixsite', 'true')
    .ifParam(
      showMobileViewQueryParam || shouldLoadMobileEditor(editorQuery),
      'showMobileView',
      'true',
    )
    .ifParam(
      editorQuery.localServerPort,
      'localServerPort',
      editorQuery.localServerPort,
    )
    .ifParam(
      editorQuery.corvidSessionId,
      'corvidSessionId',
      editorQuery.corvidSessionId,
    )
    .ifParam(
      editorQuery.shouldSkipLocalModeEncoding,
      'shouldSkipLocalModeEncoding',
      editorQuery.shouldSkipLocalModeEncoding,
    )
    .ifParam(editorQuery.migrationId, 'migrationId', editorQuery.migrationId)
    .ifParam(
      editorQuery.appDefinitionId,
      'appDefinitionId',
      editorQuery.appDefinitionId,
    )
    .ifParam(editorQuery.branchId, 'branchId', editorQuery.branchId)
    .param('lang', editorModel.languageCode)
    .paramsObj(additionalParams);

  if (isAppStudio()) {
    const devSiteAppDefId = getAppDefIdIfExists();
    builder.ifParam(
      devSiteAppDefId,
      'firstSaveExtraPayload',
      JSON.stringify({ devSiteAppDefId }),
    );
    builder.param('configName', 'appStudio').param('applicationStudio', 'open');
  }

  builder.param('languages', editorQuery.languages);

  if (editorQuery.localServerPort) {
    if (editorQuery.petri_ovr) {
      if (editorQuery.petri_ovr.includes('UseBoltInPreview:false')) {
        builder.param(
          'petri_ovr',
          editorQuery.petri_ovr.replace(
            'specs.UseBoltInPreview:false',
            'specs.UseBoltInPreview:true',
          ),
        );
      } else {
        builder.param(
          'petri_ovr',
          editorQuery.petri_ovr.concat(';specs.UseBoltInPreview:true'),
        );
      }
    }
  }

  return builder.url();
}

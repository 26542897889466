import {
  createEditorFedopsLogger,
  createEditorBiLoggerFactoryForFedops,
} from './loggers/fedopsLogger';
import { createSentryLogger } from './loggers/sentryLogger';
import { createBiLoggerFactory } from './loggers/biLogger';

import type { EditorModel, BILoggerModel, EditorLoggers } from 'types/core';
import type { EditorParams } from '../../common';

export const createEditorLoggers = ({
  editorParams,
  editorModel = {},
  biLoggerModel = {},
}: {
  editorParams: EditorParams;
  editorModel: Partial<EditorModel>;
  biLoggerModel: Partial<BILoggerModel>;
}): EditorLoggers => {
  if (biLoggerModel.metaSiteId === undefined) {
    biLoggerModel.metaSiteId = editorModel.metaSiteId;
  }

  const biLoggerFactoryForFedops =
    createEditorBiLoggerFactoryForFedops(biLoggerModel);
  const fedopsLogger = createEditorFedopsLogger(
    biLoggerModel,
    biLoggerFactoryForFedops,
  );

  const biLoggerFactory = createBiLoggerFactory({
    editorModel: editorModel as EditorModel,
    shouldBeMuted: !editorParams.isBiErrorsAndFedopsEnabled,
  });

  return {
    biLoggerFactoryForFedops,
    fedopsLogger,
    sentryLogger: createSentryLogger({
      fedopsLogger,
      editorParams,
      editorModel,
      biLoggerModel,
    }),
    biLoggerFactory,
  };
};

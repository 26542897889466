import requirejs from 'requirejs';

export interface StartupConfig {
  isIndsideEditorX: boolean;
  shouldRender: boolean;
  measureFrameRate: boolean;
  shouldBiErrorsAndFedops: boolean;
  shouldLoadPolyfills: boolean;
  publicPath: string;
  loadMainCss: boolean;
  stylablePanelTheme?: string;
}

export interface EditorParams {
  isIndsideEditorX: boolean;
  isLocal: boolean;
  isDebug: boolean;
  isQA: boolean;
  isBiErrorsAndFedopsEnabled: boolean;
}

export const requirePromise = <T>(modulesToLoad: string[]) =>
  new Promise<T>((resolve, reject) =>
    requirejs(modulesToLoad, resolve, reject),
  );

import type { EditorModel } from 'types/core';
import type { FedopsLogger } from 'types/fedops';
import { requirePromise, StartupConfig } from '../common';
import { EditorBootstrapError, EditorBootstrapErrorCode } from '../errors';
import { loadAdditionalPackages } from './loadAdditionalPackages';
import { loadPolyfills } from './loadPolyfills';

export async function loadMainPackages(
  {
    fedopsLogger,
    isHmrReload,
    editorModel,
  }: {
    fedopsLogger: FedopsLogger;
    isHmrReload: boolean;
    editorModel: EditorModel;
  },
  config: StartupConfig,
) {
  fedopsLogger.appLoadingPhaseStart('main-chunk-load-main-packages');

  if (!isHmrReload) {
    fedopsLogger.appLoadingPhaseStart('main-chunk-load-commonn-externals');
    await Promise.all([
      requirePromise(__COMMONJS_EXTERNALS__),
      config.shouldLoadPolyfills ? loadPolyfills() : Promise.resolve(),
    ]).catch((error) => {
      throw new EditorBootstrapError('Failed to load common externals', {
        code: EditorBootstrapErrorCode.LoadPackagesError,
        originalError: error,
      });
    });
    fedopsLogger.appLoadingPhaseFinish('main-chunk-load-commonn-externals');
  }

  fedopsLogger.appLoadingPhaseStart('main-chunk-load-rEditor');
  const [, rEditor] = await Promise.all([
    config.loadMainCss
      ? Promise.all([
          //eslint-disable-next-line @wix/santa-editor/scoped-imports
          import('@/rEditor/editor.global.scss'),
          /**
           * Fonts that we use are compressed and some symbols are missing.
           * Because of this, Vietnamese looks bad. Different symbols are rendered with different fonts.
           * We need to use other fonts. This is expected to be fixed by uploading full versions of fonts,
           * but meanwhile we need to add fallback fonts for Vietnamese locale
           * TODO: remove editor-vi-fonts.global.scss after main fonts are updated to include all needed symbols
           */
          editorModel.languageCode === 'vi'
            ? //eslint-disable-next-line @wix/santa-editor/scoped-imports
              import('@/rEditor/editor-vi-fonts.global.scss')
            : undefined,
        ])
      : undefined, // should be loaded first because of css ordering
    import('@/rEditor'),
  ]).catch((error) => {
    throw new EditorBootstrapError('Failed to load @/rEditor', {
      code: EditorBootstrapErrorCode.LoadPackagesError,
      originalError: error,
    });
  });
  fedopsLogger.appLoadingPhaseFinish('main-chunk-load-rEditor');

  fedopsLogger.appLoadingPhaseStart('main-chunk-load-additional-packages');
  const additionalPackages = await loadAdditionalPackages({
    queryUtil: window.queryUtil,
  }).catch((error) => {
    throw new EditorBootstrapError('Failed to load additional packages', {
      code: EditorBootstrapErrorCode.LoadPackagesError,
      originalError: error,
    });
  });
  fedopsLogger.appLoadingPhaseFinish('main-chunk-load-additional-packages');

  fedopsLogger.appLoadingPhaseFinish('main-chunk-load-main-packages');

  return {
    rEditor,
    additionalPackages,
  };
}
